const localState = {

    step: null,

    id: null,
    device_id: null,
    starts_at: null,
    ends_at: null,
    date: null,
    type: null,
}

export default {

    state: {...localState},

    getters: {
        booking: state => name => state?.[name] || null,

        processBooking: state => state.step !== null,

        bookingType: state => state.type || null,
    },

    mutations: {
        booking(state, data) {
            Object.keys(data).forEach(key => state[key] = data[key])
        },

        setType(state, type){
            state.type = type ? type.toLowerCase() : null;
        },

        processBooking(state, step = null){
            state.step = step || 'calendar';
        },

        resetBooking(state) {
            Object.assign(state, localState);
        }
    },

}
