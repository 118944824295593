let localState = {

    types: false,
    registration: false,
    otp: false,
    details: false,

};


export default {

    state: {...localState},

    getters: {

        types: state => state.types,

        registration: state => state.registration,

        otp: state => state.otp,

        details: state => state.details,

    },

    mutations: {

        types(state, data) {
            state.types = data;
        },

        registration(state, data) {
            state.registration = data;
        },

        otp(state, data) {
            state.otp = data;
        },

    }
}
