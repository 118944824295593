<template>
    <v-card class="registration-dialog" id="types_dialog">
        <v-toolbar dark color="#4CC3CA">
            <v-toolbar-title>
                Выберите развлечение
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn fab icon @click="$store.commit('types', false)">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <div class="center device-sheet">
            <v-sheet>
                <v-main>
                    <div class="device-list">
                        <div v-for="(device,key) in types" :key="key" class="device-list-item">
                            <v-card class="device-card">
                                <v-list-item three-line @click="selected(device.type.toLowerCase())">
                                    <v-list-item-avatar rounded width="100" height="80">
                                        <v-img :src="device.img"></v-img>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ device.name }}</v-list-item-title>
                                        <v-list-item-subtitle class="mb-2">{{device.description_small }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-card>
                        </div>
                    </div>
                </v-main>
            </v-sheet>
        </div>
    </v-card>
</template>

<script>
import types from '../../misc/types';

export default {

    name: 'VTypesDialog',


    data() {
        return {
            types,
            loading: true,
        }
    },


    methods: {
        selected(type) {
            this.$store.commit('types', false);
            this.$router.push({name:'devices', params:{type}})
        }

    }
}
</script>

<style lang="scss">
.v-list-item--disabled .v-image__image--cover {
    opacity: 0.5;
}
#types_dialog {
    .v-toolbar__content {
        padding: 16px !important;
    }
}
</style>