<template>
    <v-dialog v-model="local" :fullscreen="fullscreen" persistent transition="dialog-bottom-transition"  id="details-dialog">

        <v-card class="registration-dialog">
            <v-toolbar dark color="#4CC3CA">
                <v-toolbar-title>Информация о поездке</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn fab icon @click="local = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-main class="registration-sheet pt-6">
                <v-card-text class="pa-4 ma-0 fuga-info text-center">
                    <div class="mb-6">
                        <div class="b2 pb-1">Ваша поездка состоится:</div>
                        <div class="st1 pb-2"><b>{{ date }} в {{ starts }} - {{ ends }}</b></div>
                        <div v-if="acceptedPromo"><b class="accepted-promo">+10 минут бесплатно</b></div>
                    </div>
                    <div class="mb-6">
                        <div class="b2 pb-1">Время возврата в порт:</div>
                        <div class="st1"><b>{{ return_time }}</b></div>
                    </div>

                    <div class="ma-1">
                        <div class="b2">
                            {{ duration }} минут катания на <b>{{ device.name }} ({{ device.type }})</b>
                            на точке
                            <b>Яхта «Романтика»</b>(Россия, Морской вокзал Сочи, причал D) за <b>{{ price }} ₽</b>
                        </div>
                    </div>

                    <div class="ma-1 mt-8">
                        <v-switch color="#4CC3CA" label="У меня есть промокод" v-model="hasPromo" :disabled="acceptedPromo"/>
                    </div>
                    <div v-if="hasPromo" class="ma-1 row">
                        <v-text-field v-model="promo" outlined required
                                      :disabled="acceptedPromo" :error-messages="promoErr"/>
                        <v-btn v-if="!acceptedPromo"  block dark color="#FF6D2C" @click="accept">Применить</v-btn>
                    </div>
                </v-card-text>
                <p class="text-center notice">
                    Компания вправе предложить перенести или аннулировать бронирование поездки в случае несоответствия погодных
                    условий
                </p>
                <v-btn rounded block dark color="#FF6D2C" @click="pay('income')" class="mt-4 mb-4">Перейти к оплате</v-btn>
                <v-btn rounded block outlined color="#FF6D2C"  @click="pay('on_place')" class="mt-4 mb-4">Оплатить на месте</v-btn>
            </v-main>
        </v-card>
    </v-dialog>
</template>

<script>
import api from '@/api';
import dayjs from 'dayjs';
import forms from '@/mixins/forms';
import calculator from "@/mixins/calculator";

export default {

    name: 'VBookingDetails',

    mixins: [forms, calculator],

    props: {
        date: {
            required: true,
            default: null
        },

        starts: {
            required: true,
            default: ''
        },

        ends: {
            required: true,
            default: ''
        },

        device: {
            required: true,
            default: () => {
            }
        },
    },

    data(){
        return {
            fullscreen: window.innerWidth <= 756,
            acceptedPromo: false,
            hasPromo: false,
            promo: '',
            promoErr: '',
        }
    },

    computed: {

        //время окончания - время начала * стоимость за минуту * 1 час(минимальное время поездки)
      price() {
        let ends = dayjs(this.date + ' ' + this.ends, 'YYYY-MM-DD HH:mm');
        let starts = dayjs(this.date + ' ' + this.starts, 'YYYY-MM-DD HH:mm');
        return this.getPrice(ends.diff(starts, 'minutes'), this.device.tariff);
      },

        return_time() {
            return dayjs(this.date + ' ' + this.starts, 'YYYY-MM-DD HH:mm')
                .add(140, 'minutes').format('HH:mm')
        },

        duration() {
            let ends = dayjs(this.date + ' ' + this.ends, 'YYYY-MM-DD HH:mm');
            let starts = dayjs(this.date + ' ' + this.starts, 'YYYY-MM-DD HH:mm');
            return ends.diff(starts, 'minutes');
        }

    },

    methods: {
        pay(type = 'income') {
            api
                .payment({
                    booking_id: this.$store.state.booking.id,
                    type
                })
                .then((result) => {
                    if (result) {
                        this.$store.commit('resetBooking');
                        if(type === 'income') {
                            window.location.href = result.bank_url;
                        }else if(type === 'on_place'){
                            window.location.href = '/?paymentSuccess';
                        }
                    }
                })
        },

        cancel() {
            api.cancel(this.$store.state.booking.id);
            this.$emit('cancel')
        },

        accept() {
            api.promo(this.promo, this.$store.state.booking.id)
                .then(result => {
                    this.acceptedPromo = result.success;
                    if(!result.success){
                        throw Error();
                    }
                    this.promoErr = '';
                }).catch(() => this.promoErr = 'Невозможно применить промокод')
        }
    }

}
</script>

<style lang="scss">
.fuga-info .v-label {
    font-size: 12px;
}
.accepted-promo {
    font-size: 16px;
    margin-top: 10px;
    color: red;
}

#details-dialog {
    .v-toolbar__content {
        padding: 16px !important;
    }
}
</style>