import axios from 'axios'
import cfg from '@/misc/config';
import {API_URL} from "@/misc/constants";

const http = axios.create({
    baseURL: process.env.ENV_NAME === 'production' ? '/' : API_URL,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
    },
    defaults: {
        withCredentials: true
    }
});

http.interceptors.request.use((config) => {
    if (config.url.indexOf('/api/config') >= 0) {
        cfg.reconfiguring = true;
    } else if (cfg.reconfiguring) {
        return new Promise((resolve) => {
            let timerId = setTimeout(() => {
                // if (window.config.isProduction) {
                //     captureErrorAction("Configuration timeout expired ", JSON.stringify(config))
                // } else {
                    console.error("Configuration timeout expired " + JSON.stringify(config));
                // }
            }, 60000);
            let interval = setInterval(() => {
                if (!cfg.reconfiguring) {
                    clearInterval(interval);
                    clearTimeout(timerId);
                    config.headers.common['X-CSRF-TOKEN'] = cfg.token;
                    resolve(config);
                }
            }, 100);
        })
    }
    return config
});

http.interceptors.response.use(null, (error) => {
    // if (
    //     !error ||
    //     !error.response ||
    //     !error.response.status ||
    //     (
    //         !error.response.status === 419 &&
    //         !error.response.status === 422 &&
    //         !error.response.status === 429 &&
    //         error.response.status < 200 &&
    //         error.response.status > 226
    //     )
    // ) {
    //     captureErrorHttp(error);
    // }

    if (error.config && error?.response?.status === 419) {
        http.defaults.headers.common['X-CSRF-TOKEN'] = error.response.data;
        error.config.headers['X-CSRF-TOKEN'] = error.response.data;
        return axios.request(error.config);
    }

    return Promise.reject(error);
});

export default http;
