<template>
    <v-card class="registration-dialog">
        <v-toolbar dark color="#4CC3CA">
            <v-toolbar-title>
                Календарь поездок
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn fab icon @click="$store.commit('resetBooking')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <div class="center">
            <v-sheet>
                <v-main>
                    <div class="container calendar">
                        <v-row align="center">
                            <v-col class="d-flex" cols="12">
                                <v-date-picker locale="ru-ru" no-title :allowed-dates="allowedDates"
                                               v-model="date" first-day-of-week="1" :max="maxDate"/>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="container time">
                        <v-row align="center">
                            <v-col class="d-flex" cols="12">
                                <div @click="selectDevice" class="choose-devices">
                                    <div>
                                        <v-icon color="black" size="24px" class="pr-3">mdi-surfing</v-icon>
                                        <span v-if="!date || !device">Выбрать устройство</span>
                                        <b v-if="device">{{ device.name }} ({{ device.type }})</b>
                                    </div>
                                    <div class="choose-text">выбрать</div>
                                </div>
                            </v-col>
                            <v-col class="d-flex" cols="12">
                                <v-select v-if="device" :items="start" label="Время выезда"
                                          prepend-inner-icon="mdi-clock-outline"
                                          persistent-hint
                                          v-model="starts" :hint="return_time"  outlined>
                                </v-select>
                            </v-col>
                            <v-col class="d-flex" cols="12">
                                <v-select v-if="starts" :items="end" label="Длительность поездки"
                                          prepend-inner-icon="mdi-clock-outline"
                                          v-model="ends" outlined :hint="duration_sale"
                                          persistent-hint
                                          class="duration-select">
                                    <template slot="selection" slot-scope="data">
                                        <!-- HTML that describe how select should render selected items -->
                                        {{ data.item.time }} ( {{ data.item.duration }} минут<span v-if="data.item.discount">,</span>
                                        <span v-if="data.item.discount" style="text-decoration: line-through;color: #ff0000;">{{ data.item.discount }}  ₽</span> )
                                        {{ data.item.price }}  ₽
                                    </template>
                                    <template slot="item" slot-scope="data">
                                        {{ data.item.time }} ( {{ data.item.duration }} минут<span v-if="data.item.discount">,</span>
                                        <span v-if="data.item.discount" style="text-decoration: line-through;color: #ff0000;">{{ data.item.discount }}  ₽</span> )
                                        {{ data.item.price }}  ₽
                                    </template>
                                </v-select>
                            </v-col>
                        </v-row>
                    </div>
                </v-main>
                <v-footer class="justify-end">
                    <div class="container">
                        <v-alert v-if="completed" type="info" color="#4cc3ca" dense outlined class="booking-info">
                            <p class="mb-0">
                                Сбор в месте встречи в {{ selectedDate.subtract(30, 'minutes').format('HH:mm') }}
                            </p>
                            <p class="mb-0">
                                Возвращение в порт в {{ selectedDate.add(140, 'minutes').format('HH:mm') }}
                            </p>
                        </v-alert>
                        <v-row align="center">
                            <v-col cols="12">
                                <v-row>
                                    <v-col v-if="!device" cols="12" class="text-center">
                                        Выберите доступные дату и устройство для катания
                                    </v-col>
                                    <v-col v-else-if="device && !completed" cols="12" class="text-center">
                                        Теперь выберите доступное время катания
                                    </v-col>
                                    <v-col v-else cols="12" class="text-center">
                                        <div>Вы выбрали</div>
                                        <div class="ma-1"><b>{{ date }} в {{ starts }} - {{ ends }}</b></div>
                                        <div class="ma-1"><b>{{ device.name }} ({{ device.type }})</b></div>
                                        <div class="ma-1"><b>Стоимость {{ price }} ₽</b></div>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-btn rounded block color="warning" :disabled="completed == null"
                                               @click="booking"
                                               class="mt-4 mb-4">Забронировать
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </div>
                </v-footer>
            </v-sheet>

            <v-devices v-if="showDevices" v-model="showDevices" :starts="starts" :date="date"
                       @selected="deviceSelected"/>

            <v-registration-dialog v-if="registerUser" v-model="registerUser" @registered="userRegistered"/>

            <v-otp-dialog v-if="confirmOtp" v-model="confirmOtp" :phone="$store.state.user.phone"
                          @confirmed="otpConfirmed" @change="changePhone"/>

            <v-booking-details v-if="showDetails" v-model="showDetails"
                               :starts="starts" :ends="ends" :date="date"
                               :device="device" @cancel="cancel"/>
        </div>
    </v-card>
</template>

<script>
import api from "@/api";
import VOtpDialog from "@/blocks/dialogs/otp";
import VDevices from "@/blocks/dialogs/devices";
import VBookingDetails from "@/blocks/dialogs/details";
import VRegistrationDialog from "@/blocks/dialogs/registration";
import store from '@/store';
import schedule from '@/misc/schedule';
import devices from "@/misc/devices";
import dayjs from 'dayjs';
import calculator from "@/mixins/calculator";

let cr = new Date();
let crd = cr.toISOString().slice(0, 10)

export default {

    name: 'VRegistration',

    components: {VBookingDetails, VRegistrationDialog, VOtpDialog, VDevices},

    mixins: [calculator],

    data: () => ({

        //dialogs
        showDevices: false,
        confirmOtp: false,
        registerUser: false,
        showDetails: false,

        //booking data
        date: store.state.booking.date || (schedule.days.includes(crd) ? crd : null),
        starts: store.state.booking.starts_at,
        ends: store.state.booking.ends_at,
        device: store.state.booking.device_id ? devices.find((d) => d.id === store.state.booking.device_id) : null,
        agree: false,
        otp: null,
        otp_confirmed: false,

        errors: {
            phone: null,
            agree: null,
        },

        allowedDates: val => {
            return schedule.days.includes(val) && crd <= val;
        },
        process: false,
        otpSent: false,
        is_random_device: false,
    }),

    computed: {
        completed() {
            return this.ends && this.device
        },

        price() {
            if (this.ends && this.starts && this.device) {
                let ends = dayjs(this.date + ' ' + this.ends, 'YYYY-MM-DD HH:mm');
                let starts = dayjs(this.date + ' ' + this.starts, 'YYYY-MM-DD HH:mm');
                return this.getPrice(ends.diff(starts, 'minutes'), this.device.tariff);
            }
            return 0;
        },

        start() {
            let starts_at = parseInt(schedule.starts_at);
            let ends_at = parseInt(schedule.ends_at) - schedule.duration;
            let now = dayjs();
            let res = [];

            if(this.device){
                for (let i = starts_at; i <= ends_at; i += schedule.duration) {
                    let time_str = this.date + ' ' + (i < 10 ? '0' + i : i) + ':00';
                    let time = dayjs(time_str, 'YYYY-MM-DD HH:mm')
                    let disabled = time.diff(now, 'minute') < 30;
                    let booked = this.device.bookings.find( (booking) => {
                        let starts_at = booking.starts_at.slice(0,19);
                        return dayjs(starts_at, 'YYYY-MM-DD HH:mm').isSame(time);
                    })
                    if(booked){
                        disabled = true;
                    }
                    if(schedule.excerpts.includes(time.format('YYYY-MM-DD HH:mm'))){
                        disabled = true;
                    }
                    res.push({
                        text: time.format('HH:mm'),
                        value: time.format('HH:mm'),
                        disabled: disabled,
                    })
                }
            }

            return res;
        },

        end() {
            let time = dayjs(this.date + ' ' + this.starts, 'YYYY-MM-DD HH:mm')
            let res = [];
            if (this.device) {
                for (let i = 1; i <= 60/this.device.duration; i++) {
                    let loc = time.add(i * this.device.duration, "minutes");
                    res.push({
                        time: loc.format('HH:mm'),
                        duration: i * this.device.duration,
                        price: this.getPrice(i * this.device.duration, this.device.tariff),
                        discount:  this.getSalePrice(i * this.device.duration, this.device.tariff),
                        value: loc.format('HH:mm'),
                        disabled: false,
                    })
                }
            }

            return res;
        },

        selectedDate() {
            return this.date && this.start ? dayjs(this.date + ' ' + this.starts, 'YYYY-MM-DD HH:mm') : dayjs();
        },

        maxDate() {
            return schedule.days[schedule.days.length - 1];
        },

        return_time() {
            let result;
            if (this.starts) {
                result = "Время возвращения в порт " + dayjs(this.date + ' ' + this.starts, 'YYYY-MM-DD HH:mm')
                    .add(140, 'minutes').format('HH:mm')
            }
            return result;
        },

        duration_sale() {
            let result;
            if (this.ends && this.device.tariff?.details?.until) {
                result = this.device.tariff?.details?.until;
            }
            return result;
        }

    },

    watch: {
        date() {
            this.starts = null;
            this.ends = null;
            this.device = null;
        },

        device() {
            this.starts = null;
            this.ends = null;
        }
    },

    methods: {

        selectDevice() {
            if (this.date) {
                this.showDevices = true;
            }
        },

        deviceSelected(device, random) {
            this.device = device;
            this.is_random_device = random;
        },

        otpConfirmed() {
            this.registerUser = false;
            this.confirmOtp = false;
            this.showDetails = true;
            this.otp_confirmed = true;
            let bookingData = {
                user_id: this.$store.state.user.id,
                starts_at: this.date + ' ' + this.starts,
                ends_at: this.date + ' ' + this.ends,
                device_id: this.device.id,
            };
            if(this.is_random_device){
                bookingData.details = {random: true};
            }
            api
                .booking(bookingData)
                .then((booking) => {
                    if (booking) {
                        this.$store.commit('booking', {
                            id: booking.id,
                        });
                    }
                });
        },

        userRegistered() {
            this.registerUser = false;
            this.confirmOtp = true;
        },

        changePhone() {
            this.confirmOtp = false;
            this.registerUser = true;
        },

        booking() {
            this.$store.commit('booking', {
                date: this.date,
                starts_at: this.starts,
                ends_at: this.ends,
                device_id: this.device.id,
            })
            this.registerUser = true;
        },

        cancel() {
            this.$store.commit('resetBooking')
        },

    }
}
</script>