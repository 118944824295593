import Vue from 'vue'
import Router from 'vue-router'

//Layouts
const LayoutMain  = () => import(/*webpackChunkName: "login"*/ '../views/layouts/main');

//Pages
const HomePage  = () => import(/*webpackChunkName: "login"*/ '../views/pages/home');
const TypesPage  = () => import(/*webpackChunkName: "login"*/ '../views/pages/page_types');
const PointsPage  = () => import(/*webpackChunkName: "login"*/ '../views/pages/page_point');

Vue.use(Router);

export default new Router({
    mode: 'history',

    routes: [
        {
            path: '/', component: LayoutMain,
            children: [
                {
                    path: '', name: 'home', component: HomePage
                },
                {
                    path: 'devices/:type', name: 'devices', component: TypesPage
                },
                {
                    path: 'points/:id', name: 'points', component: PointsPage
                },
            ],
        },
    ],

    scrollBehavior(to, from, position) {
        if (position) {
            return position;
        } else if (to.hash) {
            return {
                selector: to.hash,
                behavior: 'smooth',
            };
        } else {
            return {x: 0, y: 0};
        }
    }
});