<template>
    <v-app>
        <v-app-bar color="#fff" flat dense dark class="main-app-bar">

            <v-toolbar-title>
                <router-link :to="{name: 'home'}" class="logo"></router-link>
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <a href="https://t.me/fugatabula" class="mx-4 d-inline-block" >
                <v-btn fab width="44" height="44" color="#28a8e9">
                    <Icon icon="logos:telegram" width="44"/>
                </v-btn>
            </a>

            <a href="https://wa.me/79139449010" class="mr-4 d-inline-block" >
                <v-btn fab width="44" height="44" color="#46c93e">
                    <Icon icon="mdi:whatsapp" color="white" width="28"/>
                </v-btn>
            </a>

<!--            <v-btn fab width="44" height="44" color="#4cc3ca" id="mango-phone-fuga" class="mr-4 d-inline-block" @click="openMango">-->
<!--                <Icon icon="carbon:phone-voice-filled" color="white" width="24"/>-->
<!--            </v-btn>-->

            <template v-slot:extension v-if="this.$route.name === 'home'">
                <v-btn rounded dark color="#ff6d2c" height="44" width="100%" @click="$store.commit('types', true)">
                    Забронировать
                </v-btn>
            </template>
        </v-app-bar>

        <router-view/>

        <v-footer class="fuga-footer rounded-lg">
            <div class="container">
                <v-card flat tile class="lighten-1 text-left ">
                    <v-card-text class="pt-0">
                        Обучение катанию и прокат наших спортивных снарядов доступны клиентам, не имеющим ограничений по состоянию здоровья,
                        не находящимся в состоянии алкогольного опьянения или в ином состоянии измененного сознаний.
                    </v-card-text>

                    <v-divider class="mx-4"></v-divider>
                    <v-card-text class="footer-info-block">
                        <template v-if="!fullscreen">
                            <h6 class="mb-4 main-title">Наши реквизиты</h6>
                            <p><b>Юр.адрес:</b> 630554, Новосибирская область, м.р-н Новосибирский, с.п.
                                Барышевский Сельсовет, п Каинская Заимка, ул. Серебряное Озеро, зд. 2, помещ. 3-308-1
                            </p>
                            <p><b>ИНН:</b> 5410093079</p>
                            <p><b>ОГРН:</b> 1225400008635</p>
                            <h6 class="mt-4 mb-4 main-title">Документы</h6>
                            <ul class="links-official">
                                <li>
                                    <p><a href="/assets/docs/user_agreement.docx" target="_blank">Пользовательское
                                        соглашение о порядке использования сервиса,</a></p>
                                </li>
                                <li>
                                    <p><a href="/assets/docs/privacy_policy.docx" target="_blank">Политика
                                        конфиденциальности,</a></p>
                                </li>
                                <li>
                                    <p><a href="/assets/docs/rental_contract.docx" target="_blank">Договор аренды.</a>
                                    </p>
                                </li>
                            </ul>
                        </template>
                        <template v-else>
                            <v-expansion-panels accordion>
                                <v-expansion-panel>
                                    <v-expansion-panel-header class="main-title">Наши реквизиты
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <p><b>Юр.адрес:</b> 630554, Новосибирская область, м.р-н Новосибирский, с.п.
                                            Барышевский Сельсовет, п Каинская Заимка, ул. Серебряное Озеро, зд. 2,
                                            помещ. 3-308-1</p>
                                        <p><b>ИНН:</b> 5410093079</p>
                                        <p><b>ОГРН:</b> 1225400008635</p>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                                <v-expansion-panel>
                                    <v-expansion-panel-header class="main-title">Документы</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <ul class="links-official">
                                            <li>
                                                <p><a href="/assets/docs/user_agreement.docx" target="_blank">Пользовательское
                                                    соглашение о порядке использования сервиса,</a></p>
                                            </li>
                                            <li>
                                                <p><a href="/assets/docs/privacy_policy.docx" target="_blank">Политика
                                                    конфиденциальности,</a></p>
                                            </li>
                                            <li>
                                                <p><a href="/assets/docs/rental_contract.docx" target="_blank">Договор
                                                    аренды.</a></p>
                                            </li>
                                        </ul>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </template>
                    </v-card-text>
                    <v-card-text class="text-left d-md-flex">
                        <a v-for="(icon, j) in footerIcons" :key="j" :href="icon.href" class="mx-2" target="_blank">
                            <v-icon color="black" size="24px">{{ icon.name }}</v-icon>
                        </a>
                        <a href="https://t.me/fugatabula" class="mx-2" target="_blank">
                            <Icon icon="uil:telegram"/>
                        </a>
                        <a href="tel:+79139449010" class="mx-2 link-phone" target="_blank">
                            <v-icon color="black" size="24px">mdi-phone</v-icon>
                          +7 913 944 90 10
                        </a>
                    </v-card-text>

                    <v-card-text class="text-left">
                        &copy; {{ new Date().getFullYear() }} ООО "Фуга Табула"<strong></strong>
                    </v-card-text>
                </v-card>
            </div>
        </v-footer>

        <v-snackbar v-model="cookiePolicy" timeout="-1" class="cooky-policy">
            Оставаясь на нашем сайте, вы соглашаетесь c использованием файлов cookie.
            Пожалуйста, ознакомьтесь с <a href="#"> Политикой конфиденциальности</a>.
            <template v-slot:action="{ attrs }">
                <v-btn light class="btn-cookie mr-3" v-bind="attrs" @click="cookiePolicy = false">
                    Принять
                </v-btn>
            </template>
        </v-snackbar>

        <v-dialog v-model="$store.getters.processBooking" :fullscreen="fullscreen" persistent transition="dialog-bottom-transition">
            <template v-if="$store.getters.processBooking">
                <v-registration-simple v-if="$store.getters.isSimpleFlow"/>
                <v-registration v-else/>
            </template>
        </v-dialog>

        <v-dialog v-model="$store.getters.types" :fullscreen="fullscreen" persistent transition="dialog-bottom-transition">
            <template>
                <v-types-dialog/>
            </template>
        </v-dialog>


        <v-dialog v-model="bookingSuccess" :fullscreen="fullscreen" persistent transition="dialog-bottom-transition" >
            <v-card class="booking-dialog pa-6 pt-12" >
                <div class="center">
                    <v-sheet>
                        <v-main>
                            <div class="d-flex justify-center">
                                <v-img max-height="200" max-width="200" src="assets/img/smile/smile-success.svg" class="mb-8"></v-img>
                            </div>
                            <h5 class="text-center mb-6">
                                Йохохо!<br/>
                                Поездка успешно забронирована
                            </h5>
                            <p class="text-center ma-4">
                                Мы выслали вам сообщение с информацией о бронировании на указанный номер телефона.
                            </p>
                            <p class="text-center mx-4 mb-4">
                                Изменить или отменить бронирование вы можете у нашего оператора службы поддержки.
                            </p>
                            <div class="mt-12 mx-4 ">
                                <v-btn fab rounded block color="warning" height="44" @click="bookingFinish" class="mt-4 mb-4">
                                    Отлично!
                                </v-btn>
                            </div>
                        </v-main>
                    </v-sheet>
                </div>
            </v-card>

        </v-dialog>

        <v-dialog v-model="bookingError" :fullscreen="fullscreen" persistent transition="dialog-bottom-transition" >
            <v-card class="booking-dialog pa-6 pt-12" >
                <div class="center">
                    <v-sheet>
                        <v-main>
                            <div class="d-flex justify-center">
                                <v-img max-height="200" max-width="200" src="assets/img/smile/smile-error.svg" class="mb-8"></v-img>
                            </div>
                            <h5 class="text-center mb-6">
                                Что-то пошло не так...
                            </h5>
                            <p class="text-center mb-4">
                                Пожалуйста, повторите попытку или свяжитесь с нами.
                            </p>
                            <div class="mt-12">
                                <v-btn fab rounded block color="warning" height="44" @click="bookingFinish" class="mt-4 mb-4">
                                    Повторить
                                </v-btn>
                            </div>
                        </v-main>
                    </v-sheet>
                </div>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
import VRegistration from './views/pages/registration'
import VRegistrationSimple from './views/pages/registration-simple'
import {Icon} from '@iconify/vue2'
import {initGTM, initMango, windowLoaded} from "@/misc/functions";
import {GTM_CONTAINER} from "@/misc/constants";
import VTypesDialog from "@/blocks/dialogs/types";

export default {
    name: 'App',

    components: {
        VTypesDialog,
        VRegistration,
        VRegistrationSimple,
        Icon,
    },

    data: () => ({
        fullscreen: window.innerWidth <= 756,
        cookiePolicy: !localStorage.getItem('cookiePolicy'),
        bookingTypes: false,
        bookingSuccess: false,
        bookingError: false,
        footerIcons: {
            linkedin: {
                name: 'mdi-linkedin',
                href: 'https://www.linkedin.com/company/fuga-tabula'
            },
            instagram: {
                name: 'mdi-instagram',
                href: 'https://instagram.com/fugatabula'
            },
            whatsapp: {
                name: 'mdi-whatsapp',
                href: 'https://wa.me/79139449010'
            }
        },
    }),

    watch: {
        cookiePolicy() {
            localStorage.setItem('cookiePolicy', 1)
        },
    },

    created() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        this.bookingSuccess = urlParams.has('paymentSuccess');
        this.bookingError = urlParams.has('paymentFail');
    },

    beforeCreate() {
        this.$store.dispatch('configure');
    },

    mounted() {

        windowLoaded().then(() => {
            if(process.env.ENV_NAME === 'production') {
              initGTM(GTM_CONTAINER);
                initMango();
            }
        })
    },

    methods: {
        openMango() {
            document.getElementById('mgo-mcw-cb-button').click();
        },

        bookingFinish() {
            this.bookingSuccess = false;
            this.bookingError = false;
            location.href = '/';
        }
    }
};

</script>

<style lang="scss">
    @import "styles/main";
    .logo {
        background: no-repeat 0 0 url(./assets/img/logo.svg);
        display: block;
        width: 120px;
        height: 40px;
        background-size: contain;
    }
    .map {
        position: relative;
        width: 100%;
        height: 406px;
        background: url(assets/img/yandex-map2.webp) no-repeat transparent 60% 0;
        @media (max-width: 540px) {
            background-position: 30% 0;
          background-size: cover;
        }
    }
</style>