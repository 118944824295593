<template>
    <div class="registration-sheet">
        <h5 class="text-center">Благодарим за бронирование!</h5>
        <v-card class="fuga-info text-center">
            <v-card-text class="pa-0">
                <p class="text-center">
                    В ближайшие минуты вам поступит звонок от нашего оператора, чтобы уточнить время и пролжительность поездки.
                </p>
                <div class="text-center">
                    <v-progress-circular :rotate="360" :size="200" :width="10" :value="progress" color="#4CC3CA">
                        {{ minutes }} : {{ seconds }}
                    </v-progress-circular>
                </div>
            </v-card-text>
        </v-card>
        <v-btn rounded block color="warning" @click="cancel" class="mt-4 mb-4">Отлично</v-btn>
    </div>
</template>

<script>
import timer from '@/mixins/sms-timer'

export default {

    name: 'VBookingSimpleSuccess',

    mixins: [timer],

    computed: {
        minutes(){
            return '0' + parseInt(this.toResend / 60);
        },

        seconds(){
            let s = this.toResend % 60;
            return s < 10 ? '0' + s : s;
        },
        progress(){
            return this.toResend / (5*60) * 100
        },
    },

    beforeDestroy () {
        localStorage.setItem("timer", this.toResend);
    },
    mounted () {
        let val = localStorage.getItem("timer") || 5*60
        this.setTimer(val);
    },

    methods: {
        cancel() {
            this.$emit('cancel')
        }
    }
}
</script>