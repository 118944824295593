export default {
    data() {
        return {
            toResend: 0,
            interval: null,
        }
    },

    methods: {
        setTimer(timeout) {
            if (timeout <= 0) {
                return;
            }

            this.toResend = timeout;
            clearInterval(this.interval);

            this.interval = setInterval( () => {
                if (--this.toResend <= 0) {
                    this.$emit('end-timer');
                    clearInterval(this.interval);
                }
            }, 1000);
        },

        resetTimer(err) {
            if (err?.response?.status === 429) {
                this.setTimer(err.response.headers['retry-after'] || 60);
            }
        },

        clearTimer() {
            this.toResend = 0;
        }
    }
}
