export default store => {

    let init = JSON.stringify(store.state);
    let syncing = false;

    store.subscribe((mutation, state) => {

        //Если находимся в синхронизации - не пишем в localStorage
        if (!syncing) {

            switch (mutation.type) {

                case 'logout':
                    store.commit('__toInit', JSON.parse(init));
                    break;

                default:
                    localStorage.setItem('state', JSON.stringify(state));
            }

            //"Спровоцируем" выполнение мутации в других вкладках
            window.localStorage.setItem('shared', JSON.stringify(mutation));
            window.localStorage.removeItem('shared');

        }

    });

    //Будем слушать изменения localStorage
    window.addEventListener('storage', event => {

        //Если меняется shared
        if (event.key === 'shared' && event.newValue) {
            try{
                //Запускаем выполнение мутации state
                syncing = true;
                let mutation = JSON.parse(event.newValue);
                store.commit(mutation.type, mutation.payload);

            } catch(e) {
                //do nothing
            }
            //TODO:finally
            //Завершение синхронизации строго в конце "потока"
            setTimeout(()=>syncing = false, 0)

        }

    })
}
