import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
// import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#231F20',
                // secondary: 'rgba(35,31,32,0.6)',
                // disabled: 'rgba(35,31,32,0.38)',
                hyperlink: '#009099'
            },
        },
    },
})
