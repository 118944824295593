export default class Booking{

    constructor(data = {}) {
        this.data = data.data;
    }

    get id() {
        return this.data.booking_id;
    }

}