<template>
  <v-bottom-sheet v-model="local" content-class="device-sheet">
    <h5 class="text-center main-"> Наши устройства</h5>
    <div class="device-list">
<!--        <div class="device-list-item">-->
<!--            <v-skeleton-loader v-if="loading" class="mx-auto" min-height="100" tile type="list-item-avatar-three-line" />-->
<!--            <v-card v-else class="device-card">-->
<!--                <v-list-item three-line @click="selectedRandom">-->
<!--                    <v-list-item-avatar rounded width="100" height="80" >-->
<!--                        <v-img src="../../assets/img/devices/square/random.png"></v-img>-->
<!--                    </v-list-item-avatar>-->
<!--                    <v-list-item-content>-->
<!--                        <v-list-item-title>Любое из свободных</v-list-item-title>-->
<!--                        <v-list-item-subtitle class="mb-2">eFoil, eJet</v-list-item-subtitle>-->
<!--                    </v-list-item-content>-->
<!--                </v-list-item>-->
<!--            </v-card>-->
<!--        </div>-->
      <div v-for="device in devices" :key="device.id + 1" class="device-list-item">
        <v-skeleton-loader v-if="loading" class="mx-auto" min-height="100" tile type="list-item-avatar-three-line" />
        <v-card v-else class="device-card">
          <v-list-item three-line @click="selected(device)">
            <v-list-item-avatar rounded width="100" height="80" >
              <v-img :src="'../assets/img/devices/square/'+ device.img"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ device.name }}</v-list-item-title>
              <v-list-item-subtitle class="mb-2">{{ device.type }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </div>
    </div>
  </v-bottom-sheet>
</template>

<script>
import api from '@/api';
import forms from '@/mixins/forms';

export default {

  name: 'VDevicesDialog',

  mixins: [forms],

  props: {
    value: {
      type: Boolean,
      default: false
    },

    starts: {
      required: true,
      default: ''
    },

    date: {
      required: true,
      default: ''
    },

  },

  data() {
      return {
        devices: [],
        loading: true,
      }
  },

    computed: {
      deviceType(){
          return this.$store.getters.bookingType;
      }
    },

  created() {
    api
        .devices(this.date)
        .then( (devices) =>{
          this.loading = false;
          if(this.deviceType){
              this.devices = devices.filter(device => device.type.toLowerCase() === this.deviceType)
          }else {
              this.devices = devices
          }
        })
  },

  methods: {
    selected(device, random = false) {
      this.$emit('selected', device, random);
      this.local = false;
    },

      selectedRandom(){
        let filtered = this.devices.filter( item => ['eJet', 'eFoil'].includes(item.type));
        let device = filtered[Math.floor(Math.random() * filtered.length)];
        return this.selected(device, true);

      }

  }
}
</script>

<style lang="scss">
  .v-list-item--disabled .v-image__image--cover{
    opacity: 0.5;
  }
</style>