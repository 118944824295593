export default {

    methods: {

        getPrice(duration, tariff) {
            let price = tariff?.price;
            let keys = Object.keys(tariff?.details?.prices || {});
            if (keys.length) {
                let tariffDuration = keys.filter(key => key <= duration).pop();
                price = tariff.details?.prices[tariffDuration];
            }
            return Math.ceil(duration * price/100);
        },

        getSalePrice(duration, tariff) {
            let price = 0;
            let keys = Object.keys(tariff.details?.sales || {});
            if (keys.length) {
                let tariffDuration = keys.filter(key => key <= duration).pop();
                price = tariff.details?.sales[tariffDuration];
            }
            return Math.ceil(duration * price/100);
        }
    },
}
