import Config from "@/api/DTO/config";
import http from "./http";
import User from "@/api/DTO/user";
import Payment from "@/api/DTO/payment";
import Booking from "@/api/DTO/booking";
import Device from "@/api/DTO/device";
import Point from "@/api/DTO/point";
import cfg from '@/misc/config';

const api = {
    async config() {
        try{
            let { data, headers } = await http.get('/api/config');
            cfg.reconfiguring = false;

            let config = new Config(data, headers);

            cfg.token = config.token;
            http.defaults.headers.common['X-CSRF-TOKEN'] = config.token;

            return config
        }catch (e) {
            return null
        }
    },

    async register(phone) {
        try {
            let {data} = await http.post('/api/users/register', {phone})

            return new User(data);
        }catch (e) {
            return null;
        }

    },

    async collect(phone,point,type) {
        try {
            await http.post('/api/users/collect', {phone,point,type})

            return true;
        }catch (e) {
            return false;
        }

    },

    async code() {
        try {
            await http.get('/api/otp/code')

            return true;
        }catch (e) {
            return false;
        }

    },

    async confirm(code) {
        let {data} = await http.post('/api/otp/confirm', {code})

        return new User(data);
    },

    async resend(phone) {
        try {
            await http.post('/api/otp/resend', {phone})

            return true;
        }catch (e) {
            return false;
        }

    },

    async booking(body) {
        try {
            let { data } = await http.post('/api/booking', body)

            return new Booking(data);
        }catch (e) {
            return false;
        }

    },

    async cancel(id) {
        try {
            await http.delete('/api/booking/' + id)

            return true;
        }catch (e) {
            return false;
        }

    },

    async payment(body) {
        try {
            let { data } = await http.post('/api/payments/form', body)

            return new Payment(data);
        }catch (e) {
            return false;
        }

    },

    async devices(date = null) {
        try {
            let { data } = await http.get('/api/devices?date=' + ( date || '' ) )

            let result = [];

            for(let device of data.data) {
                result.push(new Device(device));
            }
            return result;
        }catch (e) {
            return false;
        }

    },

    async points() {
        try {
            let { data } = await http.get('/api/points')

            return new Point(data);
        }catch (e) {
            return false;
        }

    },

    async callRequest() {
        try {
            await http.post('/api/customer-care/call-request')
        }catch (e) {
            return false;
        }

    },


    async promo(name, booking_id) {
        try {
            let { data } = await http.post('/api/promo/accept', {name, booking_id});

            return data;
        }catch (e) {
            return false;
        }

    },

}

window.api = api;

export default api;