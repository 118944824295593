import api from '@/api'
import cfg from '@/misc/config'

export default {

    state: {
        flow: 'simple'
    },

    actions: {

        configure ({ commit }) {

            return api.config()
                .then(data => {
                    commit('configure', data.config);

                    if (data.user) {
                        commit('user', data.user);
                        commit('login');
                    }
                    cfg.reconfiguring = false
                })
                .catch(() => {
                    cfg.reconfiguring = false
                });
        },

    },

    getters: {
        isSimpleFlow: state => state.flow === 'simple',
    },

    mutations: {

        configure(state, config){
            Object.keys(config).forEach(key => state[key] = config[key])
        },

        popup(state, popup){
            state.popup = popup
        },

        closePopup(state){
            state.popup = null
        },

    }
}
