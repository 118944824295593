import Device from "@/api/DTO/device";

export default [
    new Device({
        "id": "25123411-e14c-4ec9-bf26-a63e02642eba",
        "company_id": "0c50a17b-2885-4cdd-b6ef-16371f01a09a",
        "point_id": "1eadacdf-3d1c-4785-987b-392066d9be2b",
        "tariff_id": "22d8d658-6baa-4461-906c-c6f63c39d603",
        "name": "Flite Fliteboard",
        "img": "flite.webp",
        "type": "eFoil",
        "dimensions": "173х65х12",
        "weight": "22",
        "speed": "60",
        "max_weight": "100",
        "level": "Начинающий\nУверенный\nПродвинутый",
        "inventory_number": "3",
        "serial": "FBD13019B121",
        "status": "ok",
        "created_at": "2022-06-21 21:39:29",
        "updated_at": "2022-07-31 04:11:23",
        "deleted_at": null,
        "bookings": [],
        "tariff": {
            "id": "22d8d658-6baa-4461-906c-c6f63c39d603",
            "point_id": "1eadacdf-3d1c-4785-987b-392066d9be2b",
            "name": "Tariff 10.09",
            "price": 25000,
            "created_at": "2022-09-10 18:12:50",
            "updated_at": "2022-09-10 18:12:50",
            "deleted_at": null,
            "duration": 20,
            "details": {
                "sales": {
                    "20": 25000,
                    "40": 17500,
                    "60": 15000
                },
                "until": null,
                "prices": {
                    "20": 17500,
                    "40": 12500,
                    "60": 10833
                }
            }
        }
    }),
    new Device({
        "id": "1040a351-2275-444d-88e0-b14330b5236b",
        "company_id": "0c50a17b-2885-4cdd-b6ef-16371f01a09a",
        "point_id": "1eadacdf-3d1c-4785-987b-392066d9be2b",
        "tariff_id": "22d8d658-6baa-4461-906c-c6f63c39d603",
        "name": "NeptuneX",
        "img": "neptune.webp",
        "type": "eFoil",
        "dimensions": "168х110х12",
        "weight": "35",
        "speed": "45",
        "max_weight": "135",
        "level": "Начинающий\nУверенный\nПродвинутый",
        "inventory_number": "5",
        "serial": "NPT100270A01",
        "status": "broken",
        "created_at": "2022-06-21 21:41:07",
        "updated_at": "2022-08-08 13:15:36",
        "deleted_at": null,
        "bookings": [],
        "tariff": {
            "id": "22d8d658-6baa-4461-906c-c6f63c39d603",
            "point_id": "1eadacdf-3d1c-4785-987b-392066d9be2b",
            "name": "Tariff 10.09",
            "price": 25000,
            "created_at": "2022-09-10 18:12:50",
            "updated_at": "2022-09-10 18:12:50",
            "deleted_at": null,
            "duration": 20,
            "details": {
                "sales": {
                    "20": 25000,
                    "40": 17500,
                    "60": 15000
                },
                "until": null,
                "prices": {
                    "20": 17500,
                    "40": 12500,
                    "60": 10833
                }
            }
        }
    }),
    new Device({
        "id": "0c8aed55-9c97-4346-844c-9bc7f0b71da6",
        "company_id": "0c50a17b-2885-4cdd-b6ef-16371f01a09a",
        "point_id": "1eadacdf-3d1c-4785-987b-392066d9be2b",
        "tariff_id": "22d8d658-6baa-4461-906c-c6f63c39d603",
        "name": "Radinn Freeride Urban Rebel",
        "img": "radinn_rebel.webp",
        "type": "eJet",
        "dimensions": "190х78х17",
        "weight": "42",
        "speed": "52",
        "max_weight": "130",
        "level": "Начинающий\nУверенный\nПродвинутый",
        "inventory_number": "8",
        "serial": "RADB0095C120",
        "status": "ok",
        "created_at": "2022-07-12 21:38:00",
        "updated_at": "2022-09-01 11:40:12",
        "deleted_at": null,
        "bookings": [],
        "tariff": {
            "id": "22d8d658-6baa-4461-906c-c6f63c39d603",
            "point_id": "1eadacdf-3d1c-4785-987b-392066d9be2b",
            "name": "Tariff 10.09",
            "price": 25000,
            "created_at": "2022-09-10 18:12:50",
            "updated_at": "2022-09-10 18:12:50",
            "deleted_at": null,
            "duration": 20,
            "details": {
                "sales": {
                    "20": 25000,
                    "40": 17500,
                    "60": 15000
                },
                "until": null,
                "prices": {
                    "20": 17500,
                    "40": 12500,
                    "60": 10833
                }
            }
        }
    }),
    new Device({
        "id": "c66d1036-4672-4cc6-9593-72248b478e69",
        "company_id": "0c50a17b-2885-4cdd-b6ef-16371f01a09a",
        "point_id": "1eadacdf-3d1c-4785-987b-392066d9be2b",
        "tariff_id": "22d8d658-6baa-4461-906c-c6f63c39d603",
        "name": "Radinn Carve G3 pro phantom",
        "img": "radinn_carve.webp",
        "type": "eJet",
        "dimensions": "178х63х16",
        "weight": "52",
        "speed": "38",
        "max_weight": "130",
        "level": "Уверенный\nПродвинутый",
        "inventory_number": "2",
        "serial": "RADD0133D120",
        "status": "ok",
        "created_at": "2022-06-21 21:38:00",
        "updated_at": "2022-07-31 04:13:06",
        "deleted_at": null,
        "bookings": [],
        "tariff": {
            "id": "22d8d658-6baa-4461-906c-c6f63c39d603",
            "point_id": "1eadacdf-3d1c-4785-987b-392066d9be2b",
            "name": "Tariff 10.09",
            "price": 25000,
            "created_at": "2022-09-10 18:12:50",
            "updated_at": "2022-09-10 18:12:50",
            "deleted_at": null,
            "duration": 20,
            "details": {
                "sales": {
                    "20": 25000,
                    "40": 17500,
                    "60": 15000
                },
                "until": null,
                "prices": {
                    "20": 17500,
                    "40": 12500,
                    "60": 10833
                }
            }
        }
    }),
    new Device({
        "id": "6c366065-eb49-44ef-915b-5e616e02dc35",
        "company_id": "0c50a17b-2885-4cdd-b6ef-16371f01a09a",
        "point_id": "1eadacdf-3d1c-4785-987b-392066d9be2b",
        "tariff_id": "22d8d658-6baa-4461-906c-c6f63c39d603",
        "name": "Flite Fliteboard AIR",
        "img": "flite_air.webp",
        "type": "eFoil",
        "dimensions": "183х76х11",
        "weight": "27",
        "speed": "60",
        "max_weight": "110",
        "level": "Начинающий\nУверенный\nПродвинутый",
        "inventory_number": "1",
        "serial": "FBD42010B121",
        "status": "ok",
        "created_at": "2022-06-21 21:35:40",
        "updated_at": "2022-07-31 04:11:14",
        "deleted_at": null,
        "bookings": [],
        "tariff": {
            "id": "22d8d658-6baa-4461-906c-c6f63c39d603",
            "point_id": "1eadacdf-3d1c-4785-987b-392066d9be2b",
            "name": "Tariff 10.09",
            "price": 25000,
            "created_at": "2022-09-10 18:12:50",
            "updated_at": "2022-09-10 18:12:50",
            "deleted_at": null,
            "duration": 20,
            "details": {
                "sales": {
                    "20": 25000,
                    "40": 17500,
                    "60": 15000
                },
                "until": null,
                "prices": {
                    "20": 17500,
                    "40": 12500,
                    "60": 10833
                }
            }
        }
    }),
    new Device({
        "id": "cb0902b5-14d6-402e-ac69-6caae8c915b9",
        "company_id": "0c50a17b-2885-4cdd-b6ef-16371f01a09a",
        "point_id": "1eadacdf-3d1c-4785-987b-392066d9be2b",
        "tariff_id": "22d8d658-6baa-4461-906c-c6f63c39d603",
        "name": "Lampuga AIR",
        "img": "lampuga.webp",
        "type": "eJet",
        "dimensions": "230х75х25",
        "weight": "28",
        "speed": "50",
        "max_weight": "100",
        "level": "Начинающий\nУверенный\nПродвинутый",
        "inventory_number": "6",
        "serial": "DE-LAM00219H020",
        "status": "ok",
        "created_at": "2022-06-21 21:42:31",
        "updated_at": "2022-08-03 10:41:03",
        "deleted_at": null,
        "bookings": [],
        "tariff": {
            "id": "22d8d658-6baa-4461-906c-c6f63c39d603",
            "point_id": "1eadacdf-3d1c-4785-987b-392066d9be2b",
            "name": "Tariff 10.09",
            "price": 25000,
            "created_at": "2022-09-10 18:12:50",
            "updated_at": "2022-09-10 18:12:50",
            "deleted_at": null,
            "duration": 20,
            "details": {
                "sales": {
                    "20": 25000,
                    "40": 17500,
                    "60": 15000
                },
                "until": null,
                "prices": {
                    "20": 17500,
                    "40": 12500,
                    "60": 10833
                }
            }
        }
    }),
    new Device({
        "id": "3c56f6ff-ecd0-449b-b8c3-cf98dfeeb038",
        "company_id": "0c50a17b-2885-4cdd-b6ef-16371f01a09a",
        "point_id": "1eadacdf-3d1c-4785-987b-392066d9be2b",
        "tariff_id": "22d8d658-6baa-4461-906c-c6f63c39d603",
        "name": "Radinn Carve Tarifa",
        "img": "radinn_carve_tarifa.webp",
        "type": "eJet",
        "dimensions": "178х63х16",
        "weight": "38",
        "speed": "52",
        "max_weight": "130",
        "level": "Уверенный\nПродвинутый",
        "inventory_number": "7",
        "serial": "RADC0042C120",
        "status": "ok",
        "created_at": "2022-07-12 21:38:00",
        "updated_at": "2022-08-02 18:47:25",
        "deleted_at": null,
        "bookings": [],
        "tariff": {
            "id": "22d8d658-6baa-4461-906c-c6f63c39d603",
            "point_id": "1eadacdf-3d1c-4785-987b-392066d9be2b",
            "name": "Tariff 10.09",
            "price": 25000,
            "created_at": "2022-09-10 18:12:50",
            "updated_at": "2022-09-10 18:12:50",
            "deleted_at": null,
            "duration": 20,
            "details": {
                "sales": {
                    "20": 25000,
                    "40": 17500,
                    "60": 15000
                },
                "until": null,
                "prices": {
                    "20": 17500,
                    "40": 12500,
                    "60": 10833
                }
            }
        }
    }),
    new Device({
        "id": "26461f7b-8b9c-4f6f-91e3-e0d66874aa68",
        "company_id": "0c50a17b-2885-4cdd-b6ef-16371f01a09a",
        "point_id": "1eadacdf-3d1c-4785-987b-392066d9be2b",
        "tariff_id": "22d8d658-6baa-4461-906c-c6f63c39d603",
        "name": "Radinn Explore G3 pro",
        "img": "radin_explore.webp",
        "type": "eJet",
        "dimensions": "194х81х17",
        "weight": "45",
        "speed": "52",
        "max_weight": "130",
        "level": "Начинающий\nУверенный\nПродвинутый",
        "inventory_number": "4",
        "serial": "RAD00442I020",
        "status": "ok",
        "created_at": "2022-06-21 21:31:42",
        "updated_at": "2022-09-01 11:40:03",
        "deleted_at": null,
        "bookings": [],
        "tariff": {
            "id": "22d8d658-6baa-4461-906c-c6f63c39d603",
            "point_id": "1eadacdf-3d1c-4785-987b-392066d9be2b",
            "name": "Tariff 10.09",
            "price": 25000,
            "created_at": "2022-09-10 18:12:50",
            "updated_at": "2022-09-10 18:12:50",
            "deleted_at": null,
            "duration": 20,
            "details": {
                "sales": {
                    "20": 25000,
                    "40": 17500,
                    "60": 15000
                },
                "until": null,
                "prices": {
                    "20": 17500,
                    "40": 12500,
                    "60": 10833
                }
            }
        }
    }),
    new Device({
        "id": "ff6cf560-69dc-4b70-8672-6850faa1945b",
        "company_id": "0c50a17b-2885-4cdd-b6ef-16371f01a09a",
        "point_id": "1eadacdf-3d1c-4785-987b-392066d9be2b",
        "tariff_id": "6b3c7063-2057-404f-9255-4fdefa13324b",
        "name": "Прогулочный SUP-Board",
        "img": "sup_yellow.webp",
        "type": "SUP-board",
        "dimensions": null,
        "weight": null,
        "speed": null,
        "max_weight": null,
        "level": "Начинающий\nУверенный\nПродвинутый",
        "inventory_number": "10",
        "serial": "SUP02",
        "status": "ok",
        "created_at": "2022-06-21 21:41:07",
        "updated_at": "2022-09-01 11:39:50",
        "deleted_at": null,
        "bookings": [],
        "tariff": {
            "id": "6b3c7063-2057-404f-9255-4fdefa13324b",
            "point_id": "1eadacdf-3d1c-4785-987b-392066d9be2b",
            "name": "New Tariff for SUP",
            "price": 3333,
            "created_at": "2022-06-21 21:13:50",
            "updated_at": "2022-06-21 21:13:50",
            "deleted_at": null,
            "duration": 60,
            "details": null
        }
    }),
    new Device({
        "id": "0bde6337-fe25-4394-88ed-dd7a69da904f",
        "company_id": "0c50a17b-2885-4cdd-b6ef-16371f01a09a",
        "point_id": "1eadacdf-3d1c-4785-987b-392066d9be2b",
        "tariff_id": "6b3c7063-2057-404f-9255-4fdefa13324b",
        "name": "Прогулочный SUP-Board",
        "img": "sup_blue.webp",
        "type": "SUP-board",
        "dimensions": null,
        "weight": null,
        "speed": null,
        "max_weight": null,
        "level": "Начинающий\nУверенный\nПродвинутый",
        "inventory_number": "9",
        "serial": "SUP01",
        "status": "ok",
        "created_at": "2022-06-21 21:41:07",
        "updated_at": "2022-09-01 11:39:55",
        "deleted_at": null,
        "bookings": [],
        "tariff": {
            "id": "6b3c7063-2057-404f-9255-4fdefa13324b",
            "point_id": "1eadacdf-3d1c-4785-987b-392066d9be2b",
            "name": "New Tariff for SUP",
            "price": 3333,
            "created_at": "2022-06-21 21:13:50",
            "updated_at": "2022-06-21 21:13:50",
            "deleted_at": null,
            "duration": 60,
            "details": null
        }
    })
]