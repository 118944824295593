export default class User{

    constructor(data = {}) {
        this.data = data.data;
    }

    get id() {
        return this.data?.id || null;
    }

    get phone() {
        return this.data?.phone || null;
    }

    get name() {
        return this.data?.name || null;
    }

    get phone_confirmed_at() {
        return this.data?.phone_confirmed_at || null;
    }

    toArray(){
        return {
            id: this.data?.id,
            phone: this.data?.phone,
            name: this.data?.name,
            phone_confirmed_at: this.data?.phone_confirmed_at,
        }
    }

}