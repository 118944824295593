export default {

    props: {

        name: {
            type: String,
            default: null,
        },

        value: {
            default: null,
        },

        placeholder: {
            type: String,
            default: null
        },

    },

    computed: {

        local: {
            get() {
                return this.value
            },

            set(value) {
                this.$emit('input', value);
            }
        },
    },
}
