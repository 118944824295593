<template>
    <v-card class="registration-simple-dialog">
        <v-toolbar dark color="#4CC3CA">
            <v-toolbar-title>Бронирование</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn fab icon @click="$store.commit('resetBooking')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <div class="center">
            <v-main>
                <v-registration-dialog v-if="registerUser" @registered="userRegistered"/>
                <v-booking-simple-success v-if="showDetails" @cancel="cancel"/>
            </v-main>

            <v-otp-dialog v-if="confirmOtp" v-model="confirmOtp" :phone="$store.state.user.phone"
                          @confirmed="otpConfirmed" @change="changePhone"/>
        </div>
    </v-card>
</template>

<script>
import api from "@/api";
import VOtpDialog from "@/blocks/dialogs/otp";
import VBookingSimpleSuccess from "@/blocks/dialogs/simple-success";
import VRegistrationDialog from "@/blocks/dialogs/registration";

export default {

    name: 'VRegistrationSimple',

    components: {
        VBookingSimpleSuccess,
        VRegistrationDialog,
        VOtpDialog
    },

    data: () => ({

        //dialogs
        showDevices: false,
        confirmOtp: false,
        registerUser: true,
        showDetails: false,

        //booking data
        agree: false,
        otp: null,
        otp_confirmed: false,

        errors: {
            phone: null,
            agree: null,
        },
        process: false,
        otpSent: false
    }),

    methods: {
        otpConfirmed() {
            this.registerUser = false;
            this.confirmOtp = false;
            this.showDetails = true;
            this.otp_confirmed = true;
            api.callRequest();

        },

        userRegistered() {
            this.confirmOtp = true;
        },

        changePhone() {
            this.confirmOtp = false;
            this.registerUser = true;
        },

        cancel() {
            this.$store.commit('resetBooking')
        }
    }
}
</script>
