export default {
    "id": "067c93c7-76d0-4a95-adc9-e47fd452ea9c",
    "point_id": "1eadacdf-3d1c-4785-987b-392066d9be2b",
    "month": "2022-10-01",
    "days": [
        '2022-09-28','2022-09-29','2022-09-30',
        "2022-10-01", "2022-10-02", "2022-10-04", "2022-10-05", "2022-10-06", "2022-10-07",
        "2022-10-08", "2022-10-09", "2022-10-11", "2022-10-12", "2022-10-13", "2022-10-14",
        "2022-10-15", "2022-10-16", "2022-10-18", "2022-10-19", "2022-10-20", "2022-10-21",
        "2022-10-22", "2022-10-23", "2022-10-25", "2022-10-26", "2022-10-27", "2022-10-28",
        "2022-10-29", "2022-10-30",
    ],
    "starts_at": "08:00",
    "ends_at": "20:00",
    "duration": 3,
    "break_starts_at": "12:00",
    "break_ends_at": "13:00",
    "created_at": "2022-06-21T21:51:25.000000Z",
    "updated_at": "2022-06-21T21:51:25.000000Z",
    "excerpts": []
}