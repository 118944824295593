import Vue from 'vue'
import Vuex from 'vuex'
import booking from './modules/booking'
import user from './modules/user'
import config from './modules/config'
import popups from './modules/popups'
import storage from "./plugins/storage"
import { mergeDeep } from "@/misc/functions"

Vue.use(Vuex);

const store = new Vuex.Store({

    modules: {booking, user, config, popups},

    plugins: [storage],

    mutations: {

        __initStore(state) {
            let version = localStorage.getItem('version');

            if (version === process.env.VERSION && localStorage.getItem('state')) {
                let tmp = mergeDeep(state, JSON.parse(localStorage.getItem('state')));
                this.replaceState(tmp);
            } else {
                localStorage.removeItem('state');
                localStorage.setItem('version', process.env.VERSION)
            }
        },

        __toInit(state, data) {
            this.replaceState(data);
        }

    },

});

store.commit('__initStore', store.state);

window.$store = store;

export default store
