<template>
    <v-dialog v-model="local" :fullscreen="fullscreen" persistent transition="dialog-bottom-transition">

        <v-card class="registration-dialog" id="reg_dialog">

            <v-toolbar dark color="#4CC3CA">
                <v-toolbar-title>
                    Бронирование поездки
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn fab icon @click="local = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-main class="registration-sheet">
                <h5 class="text-center">Подтверждение телефона</h5>
                <p class="text-center">
                    На номер <b>{{ phone }}</b> отправлен SMS-код,
                    который является аналогом собственноручной подписи и согласием с документами
                </p>
                <div class="mt-12">
                    <v-alert v-if="throttling" type="error" dense>
                        Превышено количество попыток подтверждения телефона
                    </v-alert>
                    <v-otp-input v-model="otp" autocomplete="one-time-code" length="4" type="number"
                                 :error-messages="errors.otp" id="otp"/>
                    <p v-if="toResend > 0" class="text-center ma-6">До повторной отправки кода {{ this.toResend }} сек</p>
                    <p v-else class="text-center ma-6">
                        <a href="#" @click.prevent="resendCode">Отправить код повторно</a>
                    </p>
                    <div class="confirm-btn-block">
                        <v-btn v-if="!this.toResend" rounded block outlined color="warning" @click="$emit('change')"
                               class="mt-4 mb-4">Изменить номер телефона
                        </v-btn>
                        <v-btn rounded block color="warning" :disabled="otp.length < 4" @click="confirm" class="mt-4 mb-4">
                            Подтвердить поездку
                        </v-btn>
                    </div>
                </div>
            </v-main>

        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import forms from '@/mixins/forms';
import timer from '@/mixins/sms-timer'

export default {

    name: 'VOtpDialog',

    mixins: [forms, timer],

    props: {
        value: {
            type: Boolean,
            default: false
        },

        phone: {
            required: true,
            type: String,
            default: null
        }
    },

    data() {
        return {
            fullscreen: window.innerWidth <= 756,
            process: false,
            throttling: false,
            otp: '',
            errors: {
                otp: null,
            },
        }
    },

    created() {
        this.setTimer(60);
    },

    mounted() {
        document.getElementById('otp--0').focus();
    },

    watch: {
        otp() {
            this.errors.otp = null;
        },

        toResend(val) {
            if (val === 0 && this.throttling) {
                this.throttling = false;
            }
        },
    },

    methods: {
        confirm() {
            this.process = true;
            api.confirm(this.otp)
                .then(user => {
                    this.$store.commit('user', user.toArray())
                    this.$emit('confirmed');
                    this.local = false;
                })
                .catch(err => {
                    this.process = false;
                    if (err?.response?.status === 429) {
                        this.errors.otp = "Превышено количество попыток";
                        this.throttling = true;
                        this.resetTimer(err);
                    } else {
                        this.errors.otp = "Неверный код подтверждения";
                    }
                })
        },

        resendCode() {
            this.process = true;
            api.resend(this.$store.state.user.phone)
                .then(response => {
                    this.setTimer(60);
                    this.process = false;
                    if (response.data) {
                        this.otp = response.data
                    }
                })
                .catch(err => {
                    this.process = false;
                    this.resetTimer(err)
                })
        },
    }

}
</script>

<style lang="scss">
#reg_dialog {
    .v-toolbar__content {
        padding: 16px !important;
    }
}
</style>