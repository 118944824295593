<template>
    <v-dialog v-model="local" :fullscreen="fullscreen" persistent transition="dialog-bottom-transition">

        <v-card v-if="!$store.getters.isSimpleFlow" class="registration-dialog" id="reg_dialog">

            <v-toolbar dark color="#4CC3CA">
                <v-toolbar-title>
                    Бронирование поездки
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn fab icon @click="local = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-main class="registration-sheet">
                <p class="text-center">
                    Введите имя и номер своего мобильного телефона и ознакомьтесь с правилами использования сервиса
                </p>

                <v-text-field v-model="username" :error-messages="errors.username" label="Имя" outlined required/>
                <v-text-field v-model="phone" :error-messages="errors.phone" v-mask="'+7(###)-###-##-##'"
                              label="Телефон" outlined required/>

                <v-checkbox v-model="agree" :error-messages="errors.agree"
                            label="Я согласен со следующими документами:"
                            type="checkbox" color="#4CC3CA" required/>

                <v-card class="fuga-info ">
                    <v-btn icon class="docs" :href="'/docs?device_id=' + $store.state.booking.device_id" target="_blank">
                        PDF&nbsp;
                        <v-icon>mdi-cloud-download-outline</v-icon>
                    </v-btn>
                    <ul>
                        <li>Правила использования сервиса,</li>
                        <li>Соглашение о принятии ответственности,</li>
                        <li>Соглашение на обработку персональных данных,</li>
                        <li>Разрешение на рекламные рассылки,</li>
                        <li>Разрешение на фото-видео съемку,</li>
                        <li>Использование изображений в рекламных целях,</li>
                        <li>Аудиофиксация переговоров,</li>
                        <li>Обязательная страховка гражданской ответственности</li>
                    </ul>
                </v-card>
            </v-main>

            <v-card-actions>
                <v-btn rounded block color="warning" :disabled="!agree" @click="register" class="mt-4 mb-4">
                    Продолжить
                </v-btn>
            </v-card-actions>
        </v-card>
        <div v-else class="registration-sheet">
            <p class="text-center">
                Введите имя и номер своего мобильного телефона и ознакомьтесь с правилами использования сервиса
            </p>

            <v-text-field v-model="username" :error-messages="errors.username" label="Имя" outlined required/>
            <v-text-field v-model="phone" :error-messages="errors.phone" v-mask="'+7(###)-###-##-##'"
                          label="Телефон" outlined required/>

            <v-checkbox v-model="agree" :error-messages="errors.agree"
                        label="Я согласен со следующими документами:"
                        type="checkbox" color="#4CC3CA" required/>

            <v-card class="fuga-info ">
                <v-btn icon class="docs" :href="'/docs?device_id=' + $store.state.booking.device_id" target="_blank">
                    PDF&nbsp;
                    <v-icon>mdi-cloud-download-outline</v-icon>
                </v-btn>
                <ul>
                    <li>Правила использования сервиса,</li>
                    <li>Соглашение о принятии ответственности,</li>
                    <li>Соглашение на обработку персональных данных,</li>
                    <li>Разрешение на рекламные рассылки,</li>
                    <li>Разрешение на фото-видео съемку,</li>
                    <li>Использование изображений в рекламных целях,</li>
                    <li>Аудиофиксация переговоров,</li>
                    <li>Обязательная страховка гражданской ответственности</li>
                </ul>
            </v-card>

            <v-btn rounded block color="warning" :disabled="!agree" @click="register" class="mt-4 mb-4">
                Продолжить
            </v-btn>
        </div>
    </v-dialog>
</template>

<script>
import api from "@/api";
import forms from '@/mixins/forms';

export default {

    name: 'VRegistrationDialog',

    mixins: [forms],

    data() {
        return {
            fullscreen: window.innerWidth <= 756,
            agree: false,
            phone: '',
            username: '',
            errors: {
                username: null,
                phone: null,
                agree: null
            },
        }
    },

    computed: {
        phoneFiltered() {
            return this.phone.replace(/\D/g, '').replace(/^([78])/, '7');
        },
    },

    methods: {
        register() {
           if(/[^А-Яа-я\s]{3,}/.test(this.username)){
                this.errors.username = 'Некорректные данные';
            } else if (this.phoneFiltered.length !== 11) {
                this.errors.phone = 'Некорректный номер телефона';
            } else {
                api.register(this.phoneFiltered)
                    .then((user) => {
                        if (user) {
                            this.$store.commit('user', {
                                name: this.username,
                                phone: this.phoneFiltered,
                            })
                            this.$emit('registered', user);
                            this.local = false;
                        }
                    })
                    .catch(e => {
                        console.log(e)
                        //capture error
                    })
            }
        },
    }

}
</script>

<style lang="scss">
#reg_dialog {
    .v-toolbar__content {
        padding: 16px !important;
    }
}
</style>