export default class Config{

    constructor(data = {}, headers = {}) {
        this.data = data.data;
        this.headers = headers;
    }

    get token() {
        return this.data.token || null;
    }

    get user() {
        return this.data.user || null;
    }

    get config() {
        return this.data.config || [];
    }

}